import React from "react"
import { Link } from "gatsby"
import SimpleLayout from "../components/SimpleLayout"
import SEO from "../components/seo"
import FeedbackContent from "../components/Feedback"

const FeedbackPage = ({uri, location}) => (
    <SimpleLayout page="feedback">
        <SEO title="Feedback" />
        <div className="container mb-2">
            <div style={{ mposition:"relative", color:"#666", marginBottom: `1.45rem` }}>
                <h2 className="mt-5 mb-5 text-center" style={{color:"#333"}}>Result Of Reviews</h2>

                <FeedbackContent location={location}/>

                <h4 className="mt-3">Need Help?</h4>
                <p> If you have any questions and need help please contact us <a className="text-muted" href="mailto:support@jobsmigas.co.id">support@jobsmigas.co.id</a>
                </p>
            </div>
        </div>

    </SimpleLayout>
)

export default FeedbackPage