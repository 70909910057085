import React, { Component } from 'react';
import { sendMessageValidate } from "../../validations/user"
import classnames from "classnames"
import { getFeedback, postFeedback, updateFeedback } from '../../actions/jobs'
import { createNotification } from "../../utils/helpers"
import { findIndex, map, isEmpty, pullAt, isNil, isArray, split, last, forEach } from "lodash"
import ContentDetailLoader from "../../components/Loader/ContentDetailLoader"
import BoxCard from "../Common/BoxCard"

class FeedbackContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id:null,
            data:null,
            loading:true,
            submitting:false,
            skipping:false,
            found:null,
            feedback:null,
            needFeedback:false,
            name:'',
            email:'',
            subject:'',
            message:'',
            selected:[],
            note:'',
            error:'',
            errors:{},
            options:[]
        }
    }

    componentDidMount() {
        let queryString = this.props.location.search
        if( queryString == '' ) {
            let arrQuery  = split(this.props.location.href, '?')
            queryString = last(arrQuery)
        }
        const urlParams = new URLSearchParams(queryString);

        if( urlParams.get('xid') ) {
            this.setState({
                id:urlParams.get('xid')
            })
            getFeedback(urlParams.get('xid'))
                .then(res=>{
                    const row = res.data

                    if( row.data.qualifications === null ) {
                        let a = []
                        forEach(row.data.options, (val,ind) => {
                            a.push({
                                key:ind,
                                label:val
                            })
                        })
                        console.log(a)
                        this.setState({
                            options:a,
                            found:true,
                            needFeedback:false
                        })
                    } else if( row.data.qualifications && row.data.feedback === 0 ) {
                        this.setState({
                            found:true,
                            needFeedback:true
                        })
                    }
                    
                })
                .catch(err=>{
                    this.setState({
                        found:false,
                    })
                })
                .finally(()=>{
                    this.setState({
                        loading:false,
                    })
                })
        }
        
    }

    onChange(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onSubmit() {
        if( this.state.selected.length == 0 ) {
            this.setState({
                error:'Please tick min. 1 criteria before send it'
            })
            createNotification('error', 'Please tick min. 1 criteria before send it')
            return;
        }

        this.setState({submitting:true}, ()=>{
            postFeedback(this.state.id, {
                qualifications:this.state.selected,
                note:this.state.note
            }).then(res=>{
                const row = res.data
                if( ! isNil(row.data.feedback) && row.data.feedback === 0) {
                    this.setState({
                        needFeedback:true
                    })
                }
            }).catch(err=>{
                let error = err.response
                createNotification('error', error.data.message);
            }).finally(()=>{
                this.setState({
                    submitting:false
                })
            })
        })
        
    }

    onOptions(item) {
        let indx;
        if( (indx = findIndex(this.state.selected, item)) > -1 ) {
            let a = this.state.selected
            pullAt(a, [indx])
            this.setState({
                selected:a
            })
        } else {
            this.state.selected.push(item)
            let a = this.state.selected
            /*if( item.key === 'Kjhk9mn' ) {
                let b = findIndex(this.state.selected, ['key', 'haj8us'])
                if( b > -1 ) {
                    pullAt(a, [b])
                }

                let c = findIndex(this.state.selected, ['key', 'opYgh6'])
                if( c > -1 ) {
                    pullAt(a, [c])
                }
            }

            if( item.key === 'haj8us' ) {
                let d = findIndex(this.state.selected, ['key', 'Kjhk9mn'])
                if( d > -1 ) {
                    pullAt(a, [d])
                }
            }*/

            this.setState({
                selected:a
            })
        }

    }

    onSendMessage(e) {
        const userData = {
            name:this.state.name,
            email:this.state.email,
            subject:this.state.subject,
            message:this.state.message,
            feedback:true
        }

        sendMessageValidate(userData)
            .then(res=>{
                this.setState({submitting:true}, ()=>{
                    updateFeedback(this.state.id, userData)
                        .then(res=>{    
                            this.setState({
                                done:true,
                                feedback:1,
                               
                            })
                        })
                        .catch(err=>{
                            console.log(err)
                            this.setState({ errors: err.response.data.messages })
                            createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
                        })
                        .finally(()=>{
                            this.setState({
                                submitting:false,
                                needFeedback:true
                            })
                        })
                }) 
            })
            .catch(err=>{
               
                this.setState({ errors: err })
                createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
                return false;
            })
    }

    onSkip() {
        const userData = {
            feedback:false
        }

        this.setState({skipping:true}, ()=>{
            updateFeedback(this.state.id, userData)
                .then(res=>{    
                    this.setState({
                        done:true,
                        feedback:0
                    })
                })
                .catch(err=>{
                    this.setState({ errors: err })
                    createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
                })
                .finally(()=>{
                    this.setState({
                        skipping:false,
                        needFeedback:true
                    })
                })
        })
        
    }

    render() {
        const errors = this.state.errors

        //haj8us
        let b = this.state.selected

        function disableOption(item)  {
            if( isEmpty(b) ) {
                return item.key === 'qUxads' ? false : true
            } else {
                if( findIndex(b, ['key', 'haj8us']) > -1 ) {
                    return item.key === 'opYgh6' ? false : null
                }
            }

            return false;
        }

        function checkOption(item) {
            if( findIndex(b, ['key', 'haj8us']) > -1 ) {
                return item.key === 'Kjhk9mn' ? false : null
            } else if( findIndex(b, ['key', 'Kjhk9mn']) > -1 ) {
                return item.key === 'haj8us' || item.key === 'opYgh6' ? false : null
            }
        }

        if( this.state.loading === true ) {
            return <ContentDetailLoader />
        }

        if( this.state.found === false ) {
            return <BoxCard width={12} bgcolor="bg-light" styles={{color:'#333'}}>
                <div class="justify-content-center text-center">
                    <div className="mb-4">
                        <h4>Dear HR Recruitment,</h4>
                    </div>
                    <p className="lead mb-5">
                        Sorry the form is not found because the page has been expired or already posted or something went wrong!				
                    </p>                                          
                </div>
            </BoxCard>
        }

        if( this.state.done === true && this.state.feedback === 1 ) {
            return  (<BoxCard width={12} bgcolor="bg-light" styles={{color:'#333'}}>
                <div className="mb-4 justify-content-center text-center">
                    <h4>Dear HR Recruitment,</h4>

                    <p className="lead">Your message is successfully sent! Thank you for your message!</p>
                </div>
            </BoxCard>)
        }

        if( this.state.done === true && this.state.feedback === 0 ) {
            return  (<BoxCard width={12} bgcolor="bg-light" styles={{color:'#333'}}>
                <div className="mb-4 justify-content-center text-center">
                    <h4>Dear HR Recruitment,</h4>

                    <p className="lead">Thank you! We appreciate you to give feedback for our jobseeker!</p>

                    <div className="alert alert-secondary" role="alert">
                        Please note! the page will be locked down because the review is done! You could not access it for edit or view page. Please be careful and make sure the options checked in well
                    </div>
                </div>
            </BoxCard>)
        }
        
        if( this.state.needFeedback === true ) {
          
            return  (<BoxCard width={12} bgcolor="bg-light" styles={{color:'#333'}}>
                <div className="mb-4">
                    <h4>Dear HR Recruitment,</h4>
                </div>
                <div className="alert alert-success mb-5">
                    <p className="lead mb-0">
                        Thank you very much that you have given review results and notes to candidate. We really appreciate you. 
                    </p>

                </div>

                <p className="lead mb-5">
                    Send a message to Jobsmigas.com? ( optional not mandatory )
                </p> 

                <div className="form-group row">
                    <div className="col-4">
                        <label htmlFor="name" className="font-weight-bold">Name</label>
                    </div>
                    <div className="col-8">
                        <input type="text" name="name" 
                            className={classnames('form-control input-lg', {
                                'is-invalid': errors.name
                            })}
                            placeholder={"Your name"}
                            value={this.state.name}
                            onChange={this.onChange.bind(this)} 
                            id="name" />
                        {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        {errors.name && isArray(errors.name) && <div className="invalid-feedback">{map(errors.name, (val) => { return val }).join('\n\n\n')}</div>}
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-4">
                        <label htmlFor="email" className="font-weight-bold">Email Address</label>
                    </div>
                    <div className="col-8">
                        <input type="email" name="email" 
                            className={classnames('form-control input-lg', {
                                'is-invalid': errors.email
                            })}
                            placeholder={"Enter valid email address"}
                            value={this.state.email}
                            onChange={this.onChange.bind(this)} 
                            id="email" />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        {errors.email && isArray(errors.email) && <div className="invalid-feedback">{map(errors.email, (val) => { return val }).join('\n\n\n')}</div>}
                    </div>
                </div>
                
                <div className="form-group row">
                    <div className="col-4">
                        <label htmlFor="subject" className="font-weight-bold">Subject</label>
                    </div>
                    <div className="col-8">
                        <input type="text" name="subject" 
                            className={classnames('form-control input-lg', {
                                'is-invalid': errors.subject
                            })}
                            placeholder={"Enter subject"}
                            value={this.state.subject}
                            onChange={this.onChange.bind(this)} 
                            id="subject" />
                        {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                        {errors.subject && isArray(errors.subject) && <div className="invalid-feedback">{map(errors.subject, (val) => { return val }).join('\n\n\n')}</div>}
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-4">
                        <label htmlFor="message" className="font-weight-bold">Message</label>
                    </div>
                    <div className="col-8">
                        <textarea name="message" 
                            className={classnames('form-control input-lg', {
                                'is-invalid': errors.message
                            })}
                            placeholder={"Entert a message"}
                            value={this.state.message}
                            onChange={this.onChange.bind(this)} 
                            id="message" />
                        {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                        {errors.message && isArray(errors.message) && <div className="invalid-feedback">{map(errors.message, (val) => { return val }).join('\n\n\n')}</div>}
                    </div>
                </div>

                <div className="row mt-3 border-top justify-content-end text-right">
                    <div className="col-1">
                        <button type="button" disabled={this.state.submitting || this.state.skipping} className="btn mt-4 btn-outline-secondary btn-lg" onClick={this.onSkip.bind(this)}>{this.state.skipping ? 'Skipping...' : 'Skip'}</button>
                    </div>
                    <div className="col-2">
                        <button type="button" disabled={this.state.submitting || this.state.skipping} onClick={this.onSendMessage.bind(this)}
                            className="btn btn-block mt-4 mb-2 btn-danger btn-lg">{this.state.submitting ? 'Sending...' : 'Send'}</button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <div className="alert alert-warning" role="alert">
                            <strong>Disclaimer:</strong> <br/>
                            <p className="mb-1">- Please note! the page will be locked down after you complete it all! You could not access it for edit or view page. <br />Please be careful and make sure the options checked in well.</p>
                            <p>- We will send a notification that contains "not qualified" to this jobseeker if within 45 days has no review from HRD</p>
                        </div>
                    </div>
                </div>
            </BoxCard>)

        }

        return (<BoxCard width={12} bgcolor="bg-light" styles={{color:'#333'}}>
            <div className="mb-4">
                <h4>Dear HR Recruitment,</h4>
            </div>
            <p className="lead mb-5">
                Please provide review results for jobseeker with write a message or note to candidate at form below :		
            </p>

            {this.state.error != '' && <div className="alert alert-danger">
                <p className="mb-0">{this.state.error}</p>
            </div>}
            {/*disabled={disableOption(item)} checked={checkOption(item)} */}
            <div className="mb-2 mt-3">
                {this.state.options.map((item, index) => <div className="custom-control custom-checkbox mb-2">
                        <input  type="checkbox" onChange={this.onOptions.bind(this, item)} className="custom-control-input" name="qualifs" id={item.key} value={item.label} />
                        <label className="lead custom-control-label font-weight-bold" htmlFor={item.key}>{item.label}</label>
                    </div>
                )}
                
                <div className="form-group mt-4">
                    <label htmlFor="note" className="lead control-label">Note (optional)</label>
                    <textarea name="note" id="note" className="form-control" 
                     value={this.state.note}
                     onChange={this.onChange.bind(this)} 
                     cols="30" rows="10" placeholder="Enter note for this jobseeker"></textarea>
                </div>

                <div className="row mt-3 justify-content-center text-center">
                    <div className="col-12 border-top">
                        <button type="button" disabled={this.state.submitting} onClick={this.onSubmit.bind(this)} className="btn mt-4 mb-2 btn-danger btn-lg">{this.state.submitting ? 'Sending...' : 'Send Results'}</button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <div className="alert alert-warning" role="alert">
                            <strong>Disclaimer:</strong> <br/>
                            <p className="mb-1">- Please note! the page will be locked down after you complete it all! You could not access it for edit or view page. Please be careful and make sure the options checked in well.</p>
                            <p>- We will send a notification that contains "not qualified" to this jobseeker if you haven't provided result of reviews more than 45 days</p>
                        </div>
                    </div>
                </div>

            </div>
           

        </BoxCard>)
    }
}

FeedbackContent.defaultProps = {
    data:null
}


export default FeedbackContent
