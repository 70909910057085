import { Link as Glink } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import classnames from "classnames"
import { displayAvatar } from "../utils/helpers"
import UserContext from "../context/CurrentUserContext"
import Logo from "./Common/Logo"
import { isPremiumProcess } from "../utils/helpers"
import { isNil, has } from "lodash"
import 'react-notifications/lib/notifications.css'
import "../sass/main.scss"
import { connect } from "react-redux"

class SimpleHeader extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      dropdown:false,
      windowWidth:null
    }
    this.mediaQuery = {
      desktop: 1200,
      tablet: 768,
      phone: 576,
    };
  }

  componentDidMount() {
    this.setState({windowWidth: document.body.clientWidth})
  }

  onToggleShow(e) {
    e.preventDefault()
    this.setState({dropdown:!this.state.dropdown})
  }
  
  render() {
    const { siteTitle, auth } = this.props
    return (
      <UserContext.Consumer>
        {cuser => (<header className="bg-dark" 
          style={{
            marginBottom: `1.45rem`,
          }}
        >
          <div
            id="header"
            className="container"
            style={{
              padding: this.state.windowWidth < this.mediaQuery.phone ? `1.05rem 15px` : `1.45rem 15px`,
              height:this.state.windowWidth < this.mediaQuery.phone ? 120 : 100
            }}
          >
            <div className="row justify-content-center text-center">
              <div className="center col-lg-12 col-12">
                <h1 style={{ margin: 0 }}>
                  <Glink to="/">
                    <Logo alt={siteTitle} />
                  </Glink>
                </h1>
              </div>
            </div>
            
          </div>
        </header>)}
      </UserContext.Consumer>
    )
  }
}

SimpleHeader.propTypes = {
  siteTitle: PropTypes.string,
}

SimpleHeader.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({
  auth:state.auth,
  profile:state.user.profile
})

export default connect(mapStateToProps, {})(SimpleHeader)
