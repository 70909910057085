import { rest, restForm } from "../utils/rest"

const get = (endpoint, args={}) => rest().get(endpoint, {params:args}) 
const post = (endpoint, data) => rest().post(endpoint, data);
const put = (endpoint, data) => rest().put(endpoint, data);
const postForm = endpoint => restForm().post(endpoint);

export const getLatestJobs = () => {
    return new Promise((resolve, reject) => {
        const api = get('latest_jobs').then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const getAutoCompletionProv = (term) => {
    const args = {term:term}
    return new Promise((resolve, reject) => {
        const api = get('autocomplete_province', args).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const getJobs = (args) => {
    return new Promise((resolve, reject) => {
        const api = get('jobs', args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const getJobDetail = (args) => {
    return new Promise((resolve, reject) => {
        const api = get('jobs_detail', args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const applyJob = (args) => {
    return new Promise((resolve, reject) => {
        const api = post('resume', args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const getFeedback = (id) => {
    return new Promise((resolve, reject) => {
        const api = get('feedback/' + id).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}

export const postFeedback = (id, args) => {
    return new Promise((resolve, reject) => {
        const api = post('feedback/' + id, args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}


export const updateFeedback = (id, args) => {
    return new Promise((resolve, reject) => {
        const api = put('feedback/' + id, args).then(res=>{
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}