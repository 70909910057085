/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import SimpleHeader from "./SimpleHeader"
import "./layout.css"

const SimpleLayout = ({ page, children, withHeader=true }) => {
  const data = useStaticQuery(graphql`
    query MiniSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
        {withHeader && <SimpleHeader miniSiteTitle={data.site.siteMetadata.title} />}
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 1260,
                padding: `0px 1.0875rem 1.45rem`,
                paddingTop: 0,
            }}
        >
            <main>{children}</main>
        </div>
    </>
  )
}

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,

}

export default SimpleLayout
